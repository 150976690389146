import config from '../config';
import { empty } from './helpers';
import querystring, { ParsedUrlQuery } from 'querystring';
import type { VideoListTypes } from '@/types/video';
import type { PaymentMode, PaymentPlan } from '@/types/payment-mode';
import { isEmptyString } from '@/src/shared/utils/string';
import { Steps } from '@/redux/videoCreatorPopup/types';
import { FeatureTypes } from '@/src/containers/ListingVideos/components/ListingStudio/utils/types';
import { UpgradePlans } from '@/types/subscription';

export const websiteBaseUrl = config.websiteBaseUrl;
export const dashboardBaseUrl = config.dashboardBaseUrl;
export const DASHBOARD_PREFIX = '/dashboard';

function getQueryParamsIfExists(
  query?: string | ParsedUrlQuery
): string | undefined {
  if (empty(query)) {
    return undefined;
  }
  if (typeof query === 'string') {
    return query;
  }
  return querystring.stringify(query);
}

function addQueryParamsToUrlIfPresent(url: string, queryParams: string) {
  if (!empty(queryParams)) {
    return `${url}?${queryParams}`;
  } else {
    return url;
  }
}

export const Routes = {
  postAnalytics: (popup?: string) => {
    return empty(popup) ? '/post-analytics' : `/post-analytics?${popup}`;
  },
  smartAds: (
    creative?: string,
    trc_src?: string,
    goalCampaign?: string,
    listingId?: string
  ) => {
    let params = '?';
    if (creative) params += `creative=${creative}&`;
    if (trc_src) params += `trc_src=${trc_src}&`;
    if (goalCampaign) params += `goalCampaign=${goalCampaign}&`;
    if (listingId) params += `listing_id=${listingId}&`;
    return `/smart-ads${params}`;
  },
  leadsGen: () => '/leads-generation',
  leadsList: () => '/leads-list',
  leadsGenAnchor: () => '/leads-generation?landingpage=true',
  allNews: () => '/all-news',
  emailMarketingNews: () => '/email-marketing-news',
  emailMarketing: () => '/email-marketing?ca=false',
  partners: () => '/partners',
  video: (type?: string) => '/video' + (type ? `/${type}` : ''),
  videoRegular: (type: string) => `/video/${type}/regular`,
  listingVideos: (params?: {
    zillowLink?: string;
    importListingPopup?: boolean;
  }) => {
    let url = '/listing-videos';
    const searchParams = new URLSearchParams('');
    for (const key in params) {
      searchParams.append(key, params[key]);
    }
    if (searchParams.size > 0) {
      url += `?${searchParams.toString()}`;
    }
    return url;
  },
  listingStudio: (
    listingId: string,
    agentUserId: number,
    tab?: FeatureTypes
  ) => {
    let url = `/listing-studio/${listingId}?uid=${agentUserId}`;
    if (tab) {
      url += `&tab=${tab}`;
    }
    return url;
  },
  premiumBranding: () => '?premium-branding=true',
  pricing: () => '/pricing',
  pricingUk: () => '/pricing-uk',
  pricingPlatinum: (query?: { section?: 'platinum-theme-samples' }) => {
    let result = '/pricing-platinum';
    if (query != null) {
      Object.entries(query).forEach(([key, value], index) => {
        if (index === 0) {
          result += `?${key}=${value}`;
        } else {
          result += `&${key}=${value}`;
        }
      });
    }
    return result;
  },
  testimonials: () => '/testimonial',
  brokerages: () => '/brokerages',
  profile: () => '/profile',
  integration: () => '/integration',
  dashboard: {
    index: DASHBOARD_PREFIX,
    settings: (tab?: string) => {
      return tab ? `/settings?tab=${tab}` : '/settings';
    },
    videoLists: (type?: VideoListTypes) => {
      if (type != null) {
        return `/video-lists?type=${type}&sort=recommended`;
      }
      return '/video-lists';
    },
    calendar: (params?: { settings?: boolean; tab?: number }) => {
      const baseUrl = '/calendar';
      const queryParams = new URLSearchParams();
      const { tab, settings } = params ?? {};
      if (settings != null) {
        queryParams.set('settings', 'true');
      }
      if (tab != null) {
        queryParams.set('tab', JSON.stringify(tab));
      }
      return !empty(queryParams.toString())
        ? `${baseUrl}?${queryParams.toString()}`
        : baseUrl;
    },
    agentDashboard: (query?: string | ParsedUrlQuery) =>
      addQueryParamsToUrlIfPresent('/', getQueryParamsIfExists(query)),
    freeDashboard: () => '/free',
    allNews: (isSecondaryLanguage?: boolean, category?: string) => {
      let parmas = new URLSearchParams();
      if (isSecondaryLanguage) {
        parmas.append('secondary_language', 'true');
      }

      if (category) {
        parmas.append('type', category);
      }

      return dashboardBaseUrl + '/all-news?' + parmas.toString();
    },
    leadsGen: () => dashboardBaseUrl + '/leads-generation',
    videoEditing: () => '/video-editing',
    trial: () => '/trial',
    crm: () => '/crm/test',
    paymentPopup: ({
      plan,
      mode,
      source,
    }: {
      plan: PaymentPlan;
      mode: PaymentMode;
      source: string;
    }) => {
      return `/popups?popup=payment&plan=${plan}&mode=${mode}&source=${source}`;
    },
    recordIntroPopup: ({
      videoSlug,
      step,
    }: {
      videoSlug: string;
      step?: Steps;
    }) => {
      return `/popups?popup=video-creator&video=${videoSlug}&step=${step}`;
    },
  },
  auth: {
    logout: () => '/auth/logout',
  },
  landings: {
    home: () => '/',
    homeV3: () => '/home/v3',
    pricingDiamond: () => '/pricing-diamond',
    leads: () => '/leads',
    terms: () => '/terms-of-service',
    smartAds: () => '/smart-ads',
    listings: () => '/mls-listings',
    emailCampaigns: () => '/email-marketing',
    personalIntro: () => '/personal-intro',
    customizeVideo: () => '/premium-branding',
    contentFactory: (params?: { city?: string; region?: string }) => {
      const baseUrl = '/content-factory';
      const queryParams = new URLSearchParams();
      const { city, region } = params ?? {};
      if (city != null) {
        queryParams.set('city', city);
      }
      if (region != null) {
        queryParams.set('region', region);
      }
      return !empty(queryParams.toString())
        ? `${baseUrl}?${queryParams.toString()}`
        : baseUrl;
    },
    upgrade: (params: {
      plan: UpgradePlans;
      discount?: string;
      default?: PaymentMode;
      force_modes?: string;
    }) => {
      const baseUrl = '/upgrade';
      const queryParams = new URLSearchParams();
      const {
        plan,
        default: defaultMode,
        discount,
        force_modes,
      } = params ?? {};
      queryParams.set('plan', plan);
      if (!isEmptyString(defaultMode)) {
        queryParams.set('default', defaultMode);
      }
      if (!isEmptyString(discount)) {
        queryParams.set('discount', discount);
      }
      if (!isEmptyString(force_modes)) {
        queryParams.set('force_modes', force_modes);
      }
      return !empty(queryParams.toString())
        ? `${baseUrl}?${queryParams.toString()}`
        : baseUrl;
    },
    mortgageBrokers: () => '/mortgage-brokers',
    brokerages: () => '/brokerages',
    insuranceAgents: () => '/insurance-agents',
    academy: () => '/academy',
    webinar: () => '/webinar',
    publish: () => '/publish',
    socialMediaSeries: () => '/social-media-series',
    financialAdvisor: () => '/financial-advisor',
  },
  external: {
    help: 'https://help.roomvu.com/en/',
    ctct: 'https://www.constantcontact.com/partner-offer/real-estate?pn=roomvu&promotionCode=ROOMVU30&utm_source=roomvu.com&utm_medium=partner-website&utm_campaign=us_i_cn_in_pn_roomvu_integrations&cc=indirect_roomvu_integrations-hub',
    wordpressIntegrationGuide:
      'https://www.roomvu.com/academy/how-to-integrate-roomvu-into-your-wordpress-website/',
  },
};

export const withAgentDashboardPrefix = (href?: string) => {
  if (isEmptyString(href)) {
    return dashboardBaseUrl;
  }
  return dashboardBaseUrl + href;
};
