import {
  ListingsIcon,
  BigBuildingIcon,
  BuildingIcon,
  EmailIcon,
  LightCRMIcon,
  IntractiveIcon,
  SmartAdsIcon,
  ResidentialIcon,
  SchoolIcon,
  SocialMediaIcon,
  SupportIcon,
  WifiIcon,
  SocialMediaIconSm,
  SmartAdsIconSm,
  ListingsIconSm,
  LightCRMIconSm,
  EmailIconSm,
} from './Icons';
import styles from '../index.module.scss';
import { MenuData } from './types';
import React from 'react';
import { Routes } from '@/core/routes';
import { getPlatformName } from '@/src/shared/utils/socials';
import { Socials } from '@/src/shared/types/socials';
import { Icon } from '@/src/shared/components/Icon';
import Image from '../../Image';

export const productMenuItem: MenuData = [
  {
    link: Routes.landings.publish(),
    title: 'Social Media Automation',
    description:
      'Available for facebook, Instagram, LinkedIn, X, TikTok and Youtube',
    descriptionShort: 'Facebook, Instagram, LinkedIn, X, TikTok and Youtube',
    icon: <SocialMediaIcon />,
    iconSm: <SocialMediaIconSm />,
    image: '/images/menu/social-media-automation.png',
    subMenu: {
      title: 'Available on:',
      listItem: [
        {
          img: '/images/menu/facebook.png',
          title: 'Facebook',
          subTitle: 'Personal/Business',
        },
        {
          img: '/images/menu/instagram.png',
          title: 'Instagram',
        },
        {
          img: '/images/menu/linkedin.png',
          title: 'LinkedIn',
        },
        {
          img: '/images/menu/twitter.png',
          title: getPlatformName(Socials.Twitter),
        },
        {
          img: '/images/menu/tiktok.png',
          title: 'TikTok',
        },
        {
          img: '/images/icons/social/youtube-colored.svg',
          title: 'Youtube',
        },
      ],
    },
  },
  {
    link: Routes.landings.leads(),
    title: 'Light CRM',
    description:
      'Lead Management, Lead follow-up with video, Respond to leads by AI',
    icon: <LightCRMIcon />,
    iconSm: <LightCRMIconSm />,
    image: '/images/menu/light-crm.webp',
    subMenu: {
      title: 'Including:',
      listItem: [
        {
          title: 'Lead Management',
        },
        {
          title: 'Lead follow-up with video',
        },
        {
          title: 'Respond to leads by AI',
        },
      ],
    },
  },

  {
    link: Routes.landings.smartAds(),
    title: 'Smart Ads',
    description: 'Ads on Facebook & Instagram with ready-made video ads',
    descriptionShort: 'Ads on Facebook & Instagram with ready-made videos',
    icon: <SmartAdsIcon />,
    iconSm: <SmartAdsIconSm />,
    image: '/images/menu/smart-ads.webp',
    subMenu: {
      title: 'Including:',
      listItem: [
        {
          title: 'Ads on Facebook & Instagram',
        },
        {
          title: 'Ready-made Video ads',
        },
      ],
    },
  },
  {
    link: Routes.landings.listings(),
    title: 'Listings',
    description: 'Run customized video ads and automatic posting',
    icon: <ListingsIcon />,
    iconSm: <ListingsIconSm />,
    image: '/images/menu/listings.webp',
    subMenu: {
      title: 'Including:',
      listItem: [
        {
          title: 'Run Customized Video Ads',
        },
        {
          title: 'Automatic Posting',
          listing: ['Just Listed', 'Just Sold', 'Open-houses'],
        },
      ],
    },
  },
  {
    link: Routes.landings.emailCampaigns(),
    title: 'Email Campaign',
    description: 'Weekly newsletter to your mailing list',
    icon: <EmailIcon />,
    iconSm: <EmailIconSm />,
    image: '/images/menu/email-campain.webp',
    subMenu: {
      title: 'Including:',
      component: (
        <p className={styles.titleEmail}>
          Fresh weekly newsletter to your mailing list
        </p>
      ),
    },
  },
  {
    link: Routes.landings.personalIntro(),
    title: 'Personal Intro',
    description: 'Add personal intro to make videos more dynamic',
    icon: <Icon name="phone-01" fontSize={24} />,
    iconSm: <Icon name="phone-01" fontSize={20} />,
    image: '/images/menu/personal-intro.png',
    isNew: true,
  },
  {
    link: Routes.landings.customizeVideo(),
    title: 'Customize video',
    description: 'Get more design choices to make your brand stand out more',
    icon: <Icon name="palette" fontSize={24} />,
    iconSm: <Icon name="palette" fontSize={20} />,
    image: '/images/menu/customize-video.png',
    isNew: true,
  },
];

export const solutionsMenuItem: MenuData = [
  {
    link: '/',
    title: 'Real Estate Agents',
    description: 'Hyper-Local branded real estate videos',
    iconSm: <BuildingIcon />,
  },
  {
    link: Routes.landings.mortgageBrokers(),
    title: 'Mortgage Brokers',
    description: 'Manage social media with compliance',
    iconSm: <ResidentialIcon />,
  },
  {
    link: Routes.landings.brokerages(),
    title: 'Brokerages',
    description: 'Manage corporate-level social media',
    iconSm: <BigBuildingIcon />,
  },
  {
    link: Routes.landings.insuranceAgents(),
    title: 'Insurance Agents',
    description: 'Manage corporate-level social media',
    iconSm: (
      <Image
        src="/images/home-page/insurance.webp"
        width={24}
        height={24}
        alt="insurance-icon"
      />
    ),
  },
  {
    link: Routes.landings.financialAdvisor(),
    title: 'Financial Advisor',
    description: 'Hyper-Local branded financial content',
    iconSm: <Icon name="currency-dollar" fontSize={20} />,
  },
];

export const resourcesMenuItem: MenuData = [
  {
    link: Routes.landings.academy(),
    externalLink: true,
    title: 'Blog',
    description: 'Learning articles about Roomvu platform',
    iconSm: <WifiIcon />,
  },
  {
    link: Routes.external.help,
    externalLink: true,
    title: 'Help Center',
    description: 'Multiple learning resources for our users',
    iconSm: <SupportIcon />,
    target: '_blank',
  },
  {
    link: Routes.landings.webinar(),
    title: 'Webinars',
    description: 'Monthly talks with social media experts',
    iconSm: <IntractiveIcon />,
  },
  {
    link: Routes.landings.socialMediaSeries(),
    title: 'Social Media Course',
    description: 'Social media learning tutorials',
    iconSm: <SchoolIcon />,
  },
];

export const menuData = [
  {
    key: 0,
    title: 'Product',
    data: productMenuItem,
  },
  {
    key: 1,
    title: 'Solutions',
    data: solutionsMenuItem,
  },
  {
    key: 2,
    title: 'Resources',
    data: resourcesMenuItem,
  },
];
